import "./App.css"
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Login from './components/login';
import Dashboard from './components/dashboard';
function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route from="/" to="/login" />
        </Routes>
      </BrowserRouter>
  )
}

export default App