import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Link, TextField,
    Typography,
    CssBaseline, Alert
} from '@mui/material';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom'
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import MuiAppBar from '@mui/material/AppBar';
import NotificationsIcon from "@mui/icons-material/Notifications";
import logo from "../../assets/logo.png"
const defaultTheme = createTheme();



const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


function LoginUser() {
    const [formValues, setFormValues, secure] = useState({
        email: '',
        password: '',
        isUserLoggedIn: false

    });
    const navigate = useNavigate();
    const getData = (e) => {
        const { value, name } = e.target;
        setFormValues(() => {
            return {
                ...formValues,
                [name]: value,
            };
        });
    };

    let isLoggedIn = true;
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password= data.get('password');

        if(email === "demo+truerx@truerx.com" && password === "Welcome2024!"){
            isLoggedIn = true;
           console.log("isLoggedIn", isLoggedIn);
            navigate("/dashboard");
        } else {
            isLoggedIn = false;

            console.log("isLoggedIn", isLoggedIn);
        }
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <AppBar position="absolute" sx={{backgroundColor: '#00b0b9'}}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: 50,
                                    width: 150
                                }}
                                alt="TrueRx"
                                src= "https://truerx.com/static/images/trueRx-logo-fullColor-white.png"
                            />
                        </Typography>

                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        marginTop: 15,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Avatar sx={{ m: 1, backgroundColor: '#00b0b9'}}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Member Sign in
                    </Typography>
                    {/*<Alert severity="error">Email Address and Password does not match. Please try again.</Alert>*/}

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 , backgroundColor: '#00b0b9', '&:hover':{backgroundColor: '#00b0b9'}}}
                        >
                            Sign In
                        </Button>
                        {/*<Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>*/}
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            </ThemeProvider>
        </>
    );
}

export default LoginUser;