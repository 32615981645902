import React, { useState } from 'react';
import Home from './Dashboard';

const DashBoard = () => {

    return (
        <>
            <div>
                <Home />
            </div>
        </>
    );
};

export default DashBoard;