import * as React from 'react';
import {styled, createTheme, ThemeProvider, css} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './listItems';
import { ChatBox, ReceiverMessage, SenderMessage } from "mui-chat-box";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {AccountBox, ExitToApp} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {Avatar, Button, FormControl, ListItem, MenuItem, Modal, OutlinedInput, Select, TextField} from "@mui/material";
import {useEffect} from "react";
import {grey} from "@mui/material/colors";
import {SelectChangeEvent} from "@mui/material";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;


function isUserEvent(message) {
    const parsedMessage = JSON.parse(message.data);
    return parsedMessage.type === "userevent";
}

function isDocumentEvent(message) {
    const parsedMessage = JSON.parse(message.data);
    return parsedMessage.type === "contentchange";
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            '10 users included',
            '2 GB of storage',
            'Help center access',
            'Email support',
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Professional',
        subheader: 'Recommended',
        price: '15',
        description: [
            '20 users included',
            '10 GB of storage',
            'Help center access',
            'Priority email support',
            'Dedicated team',
            'Best deals',
        ],
        buttonText: 'Start now',
        buttonVariant: 'contained',
    },
    {
        title: 'Enterprise',
        price: '30',
        description: [
            '50 users included',
            '30 GB of storage',
            'Help center access',
            'Phone & email support',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function Dashboard() {

    const [mode, setMode] = React.useState('light');
    const [open, setOpen] = React.useState(false);

    const [alerts, setAlerts] = React.useState([])
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    let phone = "7328043956"

    const [personName, setPersonName] = React.useState('');
    const [member, setMember] = React.useState({});
    const [phoneNumber, setPhoneNumber] = React.useState('');

    const personas = [{
        id: 1,
        name: "John Doe",
        initial: "JD",
        dob: "01/01/2021",
        address1: "2495, E. National Hwy",
        address2: "Washington, IN 47501",
        client: "Boone County",
        tpa: "UMR",
        phone: "8048368170",
        plan: "ABC",
        deductible: "$3.00",
        pharmacy: "CVS",
        pharmacyAddress1: "Intersection of Happy",
        pharmacyAddress2: "Washington, IN 47501",
        physician: "Dr. James",
        physicianAddress1: "2958 E National Hwy",
        physicianAddress2: "Washington, IN 47501",
        fax: 888-345-3458
    },
        {
            id: 2,
            initial: "JM",
            name: "Jesse McDonald",
            dob: "01/01/2084",
            address1: "2495, E. National Hwy",
            address2: "Washington, IN 47501",
            client: "Boone County",
            tpa: "UMR",
            phone: "8125820670",
            plan: "ABC",
            deductible: "$6.00",
            pharmacy: "CVS",
            pharmacyAddress1: "Intersection of Happy",
            pharmacyAddress2: "Washington, IN 47501",
            physician: "Dr. James",
            physicianAddress1: "2958 E National Hwy",
            physicianAddress2: "Washington, IN 47501",
            fax: 888-345-3458
        },
        {
            id: 3,
            initial: "AL",
            name: "Adrienne L",
            dob: "01/01/2084",
            address1: "2495, E. National Hwy",
            address2: "Washington, IN 47501",
            client: "Boone County",
            tpa: "UMR",
            phone: "7328043956",
            plan: "ABC",
            deductible: "$6.00",
            pharmacy: "CVS",
            pharmacyAddress1: "Intersection of Happy",
            pharmacyAddress2: "Washington, IN 47501",
            physician: "Dr. James",
            physicianAddress1: "2958 E National Hwy",
            physicianAddress2: "Washington, IN 47501",
            fax: 888-345-3458
        }]

    function filterById(jsonObject, id) {return jsonObject.filter(function(jsonObject) {return (jsonObject['name'] === id);})[0];}

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value },
        } = event;

        let json = filterById(personas, value);

        setPersonName(json.name);
        setPhoneNumber(json.phone)
        setMember(json)
        setAlerts([])

    };

    const handleMessageSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let message = data.get('message');

        let sms_data = {
            phone: member.phone,
            message: message
        }
       // alert(JSON.stringify(sms_data))
       fetch(`/sms`,
            {
                method: "POST",
                mode: 'cors',
                body: JSON.stringify(sms_data),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(result => result.json())
            .then(
                document.getElementById('message').value='');

        document.getElementById('message').value=''
    }


    useEffect(() => {
        //setMember(personas[0]);
        //setPersonName(personas[0].name)
       // getAlerts()


        const interval = setInterval(() => getAlerts(), 10000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    function getAlerts() {
        let data =  document.getElementsByName('member_select')[0].value
        console.log("=========>",data)
        let json = filterById(personas, data);
        console.log("=========>",json && json.phone)
        if(json && json.phone) {
            fetch(`/sms/${json.phone}`,
                 {
                     method: "GET",
                     mode: 'cors',
                     headers: {
                         'Content-Type': 'application/json',
                     }
                 })
                 .then(result => result.json())
                 .then(result => setAlerts(result))

           // let results=[{"id":8,"from":"8446182102","message":"Nilay is Testing Twillio with MySQL....","time":"2024-04-17T18:34:09.000Z","message_id":"SMae64019ed70b7b5ff6a8782d28912149"},{"id":7,"from":"8048368170","message":"Are you there? Buddy","time":"2024-04-17T14:21:32.000Z","message_id":"SM5020e25ebc57117604e0a1ea2bc2d724"},{"id":6,"from":"8048368170","message":"Are you there Sir","time":"2024-04-17T14:12:52.000Z","message_id":"SM4d6ce324fdd38a11b012399992f460b5"},{"id":5,"from":"8048368170","message":"Are you there","time":"2024-04-17T14:06:04.000Z","message_id":"SMf3107ffacb572f5579b1434030f9b708"},{"id":4,"from":"8048368170","message":"Hello Buddy. Good to see you.","time":"2024-04-17T14:05:32.000Z","message_id":"SMefceec7267dfce9ab45b197846caf4a6"},{"id":3,"from":"8446182102","message":"Nilay is Testing Twillio with MySQL....","time":"2024-04-17T14:05:05.000Z","message_id":"SMcd678d848d9fb4d560ed62bb9f9235ad"},{"id":2,"from":"8446182102","message":"Nilay is Testing Twillio....","time":"2024-04-17T14:01:09.000Z","message_id":"SM3e41d34e344d36a53b7a3146471a4348"},{"id":1,"from":"8446182102","message":"Nilay is Testing Twillio....","time":"2024-04-17T14:00:24.000Z","message_id":"SM1aaab81959b702d67fb542ec9eb66c72"}];
          // setAlerts(results)
        }
    }
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const navigate = useNavigate();
    function logout(){
        navigate("/");
    }




    const ModalContent = styled('div')(
        ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
    );
    const StyleModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;



    return (
        <>
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open} sx={{backgroundColor: '#00b0b9'}}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 2 }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: 40,
                                    width: 150
                                }}
                                alt="TrueRx"
                                src= "https://truerx.com/static/images/trueRx-logo-fullColor-white.png"
                            />

                                <span style={{paddingLeft: 500}}> Welcome {member.name}!</span>


                        </Typography>
                        <FormControl sx={{ width: 300}}>
                            <Select name='member_select'
                                    sx={{color: 'white', height:30, "& .MuiMenuItem-root.Mui-selected": {
                                            backgroundColor: "#00b0b9"
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "#00b0b9"
                                        },
                                        "& .MuiMenuItem-root.Mui-selected:hover": {
                                            backgroundColor: "#00b0b9"
                                        }}}
                                    displayEmpty
                                    value={personName}
                                    onChange={handleChange}
                                    input={<OutlinedInput />}
                            >
                                <MenuItem disabled value="">
                                    <em>Select Member</em>
                                </MenuItem>
                                {personas.map((persona) => (
                                    <MenuItem
                                        key={persona.name}
                                        value={persona.name}
                                    >
                                        {persona.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <IconButton color="inherit" onClick={logout}>
                            <ExitToApp>

                            </ExitToApp>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        {mainListItems}
                        <Divider sx={{ my: 1 }} />
                        {secondaryListItems}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >

                    <Container
                        id="pricing"
                        sx={{
                            pt: { xs: 4, sm: 12 },
                            pb: { xs: 8, sm: 16 },
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: { xs: 3, sm: 6 },
                        }}
                    >



                        <Box
                            sx={{
                                width: { sm: '100%', md: '60%' },
                                textAlign: { sm: 'left', md: 'center' },
                            }}
                        >

                        </Box>
                        <Grid sx={{display: "flex"}} container spacing={3} alignItems="baseline" justifyContent="flex-start">

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 4,
                                            border: '1px solid',
                                            borderColor: '#00b0b9',

                                        }}>
                                        <CardContent>
                                            <Box sx={{mb: 1, display: 'flex',justifyContent: 'space-between',alignItems: 'center',}}>
                                                <Typography component="h3" variant="h5" sx={{color: '#00b0b9'}}>
                                                    <b>Benefits & Coverage</b>
                                                </Typography>
                                                <Chip  sx={{backgroundColor: 'white'}} icon={<AccountBox sx={{width:80, height:80,}} />}  size="small" />
                                            </Box>
                                            <Divider sx={{ my: 2, opacity: 1, backgroundColor: 'black.900', }}/>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>Name:</b> {member.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>DOB:</b> {member.dob}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                            <Typography>
                                                <b>Address:</b>
                                            </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                    {member.address1}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                            <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                {member.address2}
                                            </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                            <Typography>
                                                <b>Contact Info:</b>
                                            </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                            <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                <b>Cell:</b> +1{member.phone}
                                            </Typography>
                                            </Box>
                                            <Divider sx={{ my: 2, opacity:1, borderColor: 'black.500', }}/>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>Client:</b> {member.client}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>TPA:</b>  {member.tpa}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>Plan Enrolled:</b>  {member.plan}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>Preferred Pharmacy:</b> {member.pharmacy}
                                                </Typography>
                                            </Box>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                    {member.pharmacy}
                                                </Typography>
                                            </Box>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                    {member.pharmacyAddress1}
                                                </Typography>
                                            </Box>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                    {member.pharmacyAddress2}
                                                </Typography>
                                            </Box>


                                            <Divider sx={{ my: 2, opacity: 1, borderColor: 'black.500', }}/>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>Physician Name:</b> {member.physician}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography>
                                                    <b>Physician Address:</b>
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                    {member.physicianAddress1}
                                                </Typography>
                                            </Box>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Typography variant="subtitle2" sx={{color:'black.200', }}>
                                                    {member.physicianAddress2}
                                                </Typography>
                                            </Box>

                                        </CardContent>
                                    </Card>
                                </Grid>



                                <Grid item key="claims" xs={12} sm={6} md={4}>
                                    <Card sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 4,
                                        border: '1px solid',
                                        borderColor: '#00b0b9',
                                    }}>
                                        <CardContent>
                                            <Box sx={{mb: 1, display: 'flex',justifyContent: 'space-between',alignItems: 'center',color: 'black.100',}}>
                                                <Typography component="h3" variant="h5" sx={{color: '#00b0b9'}}>
                                                    <b>Claims</b>
                                                </Typography>
                                                <Chip icon={<AutoAwesomeIcon />} label="CM" size="small" sx={{'& .MuiChip-label': {color: 'primary.dark',},'& .MuiChip-icon': {color: 'primary.dark',},}}/>
                                            </Box>
                                            <Divider sx={{ my: 2, opacity: 1, borderColor: 'black.500', }}/>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Table aria-label="simple table" component="text" variant="subtitle2" sx={{color:'black.200', }}>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell  component="text" variant="subtitle2" sx={{color:'black.200', }} ><b>Claim Number</b></TableCell>
                                                            <TableCell component="text" variant="subtitle2" sx={{color:'black.200', }} ><b>Drug Name</b></TableCell>
                                                            <TableCell component="text" variant="subtitle2" sx={{color:'black.200', }}  ><b>Patient Paid Amount</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell  style={{ verticalAlign: 'top' }} >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>84574515</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>Amoxicillin 25 mg</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>$46.24</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>125454578</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>Oxycodone 25 mg</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>$16.87</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </Box>

                                        </CardContent>
                                    </Card>
                                    <Divider sx={{ my: 2, opacity: 0.2, borderColor: 'black.500', }}/>
                                    <Card sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        border: '1px solid',
                                        borderColor: '#00b0b9',
                                    }}>
                                        <CardContent>
                                            <Box sx={{mb: 1, display: 'flex',justifyContent: 'space-between',alignItems: 'center',color: 'black.100',}}>
                                                <Typography component="h3" variant="h5" sx={{color: '#00b0b9'}}>
                                                    <b>Prior Authorization</b>
                                                </Typography>
                                                <Chip icon={<AutoAwesomeIcon />} label="PA" size="small" sx={{'& .MuiChip-label': {color: 'primary.dark',},'& .MuiChip-icon': {color: 'primary.dark',},}}/>
                                            </Box>
                                            <Divider sx={{ my: 2, opacity: 0.2, borderColor: 'black.500', }}/>

                                            <Box sx={{display: 'flex',alignItems: 'baseline',color:'black.50',}}>
                                                <Table aria-label="simple table" component="text" variant="subtitle2" sx={{color:'black.200', }}>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell  component="text" variant="subtitle2" sx={{color:'black.200', }} ><b>Claim Number</b></TableCell>
                                                            <TableCell component="text" variant="subtitle2" sx={{color:'black.200', }} ><b>Drug Name</b></TableCell>
                                                            <TableCell component="text" variant="subtitle2" sx={{color:'black.200', }}  ><b>Status</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell  style={{ verticalAlign: 'top' }} >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>84574515</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>Amoxicillin 25 mg</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>Pending</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>125454578</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>Oxycodone 25 mg</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography component="text" variant="subtitle2" sx={{color:'black.200', }}>Denied</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </CardContent>
                                    </Card>

                                </Grid>




                            <Grid item key="notifications" xs={12} sm={6} md={4}>

                                <Card sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    border: '1px solid',
                                    borderColor: '#00b0b9',
                                }}>
                                    <CardContent>
                                        <Box sx={{mb: 1, display: 'flex',justifyContent: 'space-between',alignItems: 'center',color: 'black.100',}}>
                                            <Typography component="h3" variant="h8" color={'#00b0b9'}>
                                                <b>Chat with Health Strategist</b>
                                            </Typography>
                                            <Chip icon={<SendIcon color='#00b0b9' />} sx={{backgroundColor: 'white','& .MuiChip-icon': {color: '#00b0b9'}}} size="small"/>
                                        </Box>
                                        <Divider sx={{ my: 2, opacity: 1, borderColor: 'black.500', }}/>




                                        <Box component="form" onSubmit={handleMessageSubmit} >
                                            <Grid container sx={{paddingLeft: 1 }}>
                                                <TextField sx={{width: '100%'}}
                                                           required
                                                           name="message"
                                                           id="message"
                                                           label="Message" autoFocus
                                                />

                                            </Grid>

                                            <CardActions >
                                                <Button sx={{backgroundColor: '#00b0b9', '&:hover':{backgroundColor: '#00b0b9'}}}  fullWidth variant="contained" type="submit">
                                                    Send Message
                                                </Button>
                                            </CardActions>
                                        </Box>

                                    </CardContent>
                                </Card>

                                <Divider sx={{ my: 2, opacity: 0.2, borderColor: 'black.500', }}/>
                                <Card sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                    border: '1px solid',
                                    borderColor: '#00b0b9',
                                }}>
                                    <CardContent>
                                        <Box sx={{mb: 1, display: 'flex',justifyContent: 'space-between',alignItems: 'center',color: 'black.100',}}>
                                            <Typography component="h3" variant="h5" sx={{color: '#00b0b9'}}>
                                                <b>Conversation Log</b>
                                            </Typography>

                                            <Chip icon={<MailIcon sx={{color: '#00b0b9'}}/>} label="" size="small" sx={{backgroundColor:'white','& .MuiChip-icon': {color: '#00b0b9'},}}/>
                                        </Box>
                                        <Divider sx={{ my: 2, opacity: 1, borderColor: 'black.500', }}/>

                                        <Paper sx={{marginTop: 0, height:250, overflow: 'auto', border:0, borderColor:'#00b0b9',}}>
                                            <ChatBox sx={{ overflow: 'auto',borderColor:'#00b0b9'}}>
                                                {alerts.map((value) => (
                                                    <>
                                                        { value.from === member.phone ? (
                                                            <ReceiverMessage sx={{ borderColor:'#00b0b9'}} avatar={<Avatar sx={{ backgroundColor:'#00b0b9', fontSize:'8px', width:'20px', height:'20px'}}>{member.initial}</Avatar>}>
                                                                <Typography sx={{fontSize:'10px'}}>{value.message}</Typography>
                                                            </ReceiverMessage>) : (
                                                            <SenderMessage sx={{ borderColor:'#00b0b9'}} avatar={<Avatar sx={{ backgroundColor:'#00b0b9', fontSize:'8px', width:'20px', height:'20px'}}>HS</Avatar>}>
                                                                <Typography sx={{fontSize:'10px'}}>{value.message}</Typography>
                                                            </SenderMessage>
                                                        )
                                                        }
                                                    </>
                                                ))}
                                            </ChatBox>
                                           {/* <Grid container sx={{paddingLeft: 1,  borderColor:'#00b0b9' }}>


                                                {alerts.map((value) => (
                                                    <>
                                                        <Grid item xs={1}>
                                                            {value.id}
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            {value.message}
                                                        </Grid>
                                                    </>
                                                ))}

                                            </Grid>*/}
                                        </Paper>
                                    </CardContent>
                                </Card>

                            </Grid>


















                              {/*  <Grid item xs={12} sm={6} md={4}>
                                    <Card sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 4,
                                        border: '1px solid',
                                        borderColor: '#00b0b9',
                                    }}>
                                        <CardContent>
                                            <Box sx={{mb: 1, display: 'flex',justifyContent: 'space-between',alignItems: 'center',color: 'black.100',}}>
                                                <Typography component="h3" variant="h5">
                                                    Member Engagement
                                                </Typography>
                                                <Chip icon={<AutoAwesomeIcon />} label="ME" size="small" sx={{background: (theme) => theme.palette.mode === 'light' ? '' : 'none',backgroundColor: 'primary.contrastText','& .MuiChip-label': {color: 'primary.dark',},'& .MuiChip-icon': {color: 'primary.dark',},}}/>
                                            </Box>
                                            <Divider sx={{ my: 2, opacity: 0.2, borderColor: 'grey.500', }}/>

                                            <Paper sx={{marginTop: 0, height:220, overflow: 'auto', backgroundColor:'#00b0b9',}}>
                                                <Grid container sx={{paddingLeft: 1 }}>

                                                    {alerts.map((value) => (
                                                        <>
                                                            <Grid item xs={1}>
                                                                {value.id}
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                {value.message}
                                                            </Grid>
                                                        </>
                                                        ))}

                                                </Grid>
                                            </Paper>
                                            <CardActions>
                                                <Button fullWidth variant="contained" type="button" onClick={handleOpen}>
                                                    Send Message
                                                </Button>
                                            </CardActions>
                                        </CardContent>
                                    </Card>
                                </Grid>*/}

                        </Grid>
                    </Container>

                </Box>
            </Box>
        </ThemeProvider>
      </>
    );
}

export default Dashboard;