import React, { useState } from 'react';
import LoginUser from './LoginUser';

const Login = () => {

    return (
        <>
            <div>
                <LoginUser />
            </div>
        </>
    );
};

export default Login;